import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// components
import Asset from '@src/components/Asset';
import LinkButton from '@src/components/buttons/CTA';
import NewsletterSignup from '@src/components/NewsletterSignup';

// svgs
import IconFacebook from '@src/svgs/icon-facebook.svg';
import IconTwitter from '@src/svgs/icon-twitter.svg';
import IconLinkedin from '@src/svgs/icon-linkedin.svg';
import IconInstagram from '@src/svgs/icon-instagram.svg';
import SunLogo from '@src/svgs/logo__sun.svg';

// styles
import './footer.scss';

const Footer = ({ closeMenu }) => {
    const data = useStaticQuery(
        graphql`
            {
                globals: craftGlobalSetInterface(handle: { eq: "footerContent" }) {
                    ... on Craft_footerContent_GlobalSet {
                        handle
                        footerEntryList {
                            remoteId
                            id
                            title
                            uri
                        }
                        socialLinks {
                            ... on Craft_socialLinks_linkBlock_BlockType {
                                id
                                facebookLink
                                instagramLink
                                twitterLink
                            }
                        }
                        multipleImages {
                            ... on Craft_images_Asset {
                                url
                                title
                                imageFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    );
    const footerContent = data.globals;
    const entries = footerContent?.footerEntryList;
    const socials = footerContent?.socialLinks[0];
    const sponsors = footerContent?.multipleImages;

    return (
        <footer className="footer">
            <div className="footer__left flex">
                <div className="footer__flex" style={{ marginBottom: 24 }}>
                    <Link to="/" onClick={closeMenu}>
                        <SunLogo />
                    </Link>
                </div>

                <div className="footer__flex">
                    {entries &&
                        entries.map(entry => (
                            <div key={entry.id} style={{ marginBottom: 16 }}>
                                <LinkButton
                                    to={`/${entry.uri}`}
                                    copy={entry.title}
                                    invert
                                    classes="small border-solid border-1 border-black hover:border-white"
                                />
                            </div>
                        ))}

                    <ul
                        className="footer__socials flex"
                        style={{ marginTop: 12, listStyle: 'none' }}>
                        <li style={{ marginRight: 8 }}>
                            <a target="_blank" href={socials.facebookLink} rel="noreferrer">
                                <IconFacebook />
                            </a>
                        </li>
                        <li style={{ marginRight: 8 }}>
                            <a target="_blank" href={socials.instagramLink} rel="noreferrer">
                                <IconInstagram />
                            </a>
                        </li>
                        <li style={{ marginRight: 8 }}>
                            <a target="_blank" href={socials.twitterLink} rel="noreferrer">
                                <IconTwitter />
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.linkedin.com/company/o-miami-poetry-festival"
                                rel="noreferrer">
                                <IconLinkedin />
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="footer__border" style={{ marginRight: '6%', marginBottom: 24 }}>
                    <h5 style={{ marginBottom: 12, maxWidth: '250px' }}>
                        Thank you to our sponsors
                    </h5>
                    <div className="footer__sponsors" style={{ marginBottom: 12 }}>
                        {sponsors.map((sponsor, i) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Asset key={i} data={sponsor.imageFile} title={sponsor.title} />
                        ))}
                    </div>
                    <div className="footer__border" style={{ textAlign: 'left' }}>
                        <Link to="/pages/privacy-policy" style={{ marginRight: 12 }}>
                            <span style={{ fontSize: '12px' }}>Privacy Policy</span>
                        </Link>
                        <span style={{ fontSize: '12px' }}>|</span>
                        <Link to="/about-us/join-us" style={{ marginLeft: 12 }}>
                            <span style={{ fontSize: '12px' }}>Volunteer</span>
                        </Link>
                    </div>
                </div>

                <div />
            </div>

            <div className="mb-24px">
                <NewsletterSignup />
            </div>
        </footer>
    );
};

export default Footer;
